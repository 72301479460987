import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import image1 from "../assets/New/cloud-storage-background-business-network-design.jpg";
import image2 from "../assets/New/cloud-upload-icon-line-connection-circuit-board.jpg";
import image3 from "../assets/New/elegant-finger-with-computer-drawings.jpg";
import image4 from "../assets/New/hand-touching-tablet.jpg";
import image5 from "../assets/New/infographic-23.jpg";
import image6 from "../assets/New/saas-concept-collage-n.jpg";
import image8 from "../assets/New/woman-scrolling-laptop.jpg";

const services = [
  {
    title: "Management",
    description:
      "We help simplify your organization's product and service management for enhanced operational efficiency and clarity.",
    image: image1,
  },
  {
    title: "Automation",
    description:
      "Are repetitive tasks consuming your resources? We automate workflows, giving you back time to focus on growth.",
    image: image4,
  },
  {
    title: "Integration",
    description:
      "We seamlessly integrate various tools and systems, ensuring everything works well together for smooth operations.",
    image: image2,
  },
  {
    title: "Support",
    description:
      "We guarantee results with our experienced team who are committed to solving your challenges efficiently.",
    image: image3,
  },
  {
    title: "Consultation",
    description:
      "We offer tailored consultations to fit your unique needs and drive measurable results.",
    image: image8,
  },
  {
    title: "Feedback",
    description:
      "Our clients love our solutions, and we take your feedback seriously to continuously improve.",
    image: image5,
  },
  {
    title: "Trust",
    description:
      "We build trust by delivering exceptional services with reliability and transparency.",
    image: image6,
  },
];

const ServiceSection = () => {
  return (
    <Box
      id="services"
      sx={{
        flexGrow: 1,
        padding: { xs: 2, sm: 4 },
        minHeight: "80vh",
        mt: { xs: 4, sm: 8 },
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        align="center"
        sx={{
          mb: { xs: 4, sm: 8 },
          fontWeight: 500,
          fontSize: { xs: "1.8rem", sm: "2.4rem" },
        }}
      >
        Our Services
      </Typography>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", margin: "0 auto" }}
      >
        {services.map((service, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${service.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: { xs: 150, sm: 200 },
                width: "100%",
                maxWidth: 300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "bold",
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "16px", sm: "24px" },
                  fontWeight: "bold",
                  zIndex: 1,
                  background: "linear-gradient(45deg, black, transparent)",
                  padding: "0 8px",
                }}
              >
                {service.title}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{
                marginTop: 2,
                paddingX: { xs: 1, sm: 2 },
                textAlign: "center",
                fontSize: { xs: "0.9rem", sm: "1rem" },
              }}
            >
              {service.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ServiceSection;
