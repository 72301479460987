import React from "react";
import { Box, Typography } from "@mui/material";
import homeImage from "../assets/New/saas-concept-collage-n.jpg";

const MainSection = () => {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: { xs: "80vh", sm: "60vh" }, // Taller for smaller screens
          backgroundImage: `url(${homeImage})`, // Ensure the path is correct
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          color: "white",
          p: 2,
        }}
        id="home"
      >
        <Box
          sx={{
            position: "absolute",
            left: { xs: "10%", sm: "5%" }, // Adjust position for smaller screens
            top: { xs: "30%", sm: "60%" },
            width: { xs: "80%", sm: "auto" }, // Adjust width for mobile
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            sx={{ background: "linear-gradient(#1d77d18f, transparent)" }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                mb: 2,
                px: 2,
                // color: "#638e8a",
                // textShadow: "white 1px 1px",
                // background: "linear-gradient(to right, black, #638e8a)",
                WebkitBackgroundClip: "text",
                // WebkitTextFillColor: "transparent",
                display: "inline-block",
                // fontSize: { xs: "2rem", sm: "3rem", md: "4rem" }, // Responsive font size
                // color: (theme) =>
                //   theme.palette.mode === "light"
                //     ? "primary.main"
                //     : "primary.light",

                color: "white",
                textShadow: " 2px 2px #666666",
              }}
            >
              Elevate Your Business with Seamless Cloud Automation
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 4,
                px: 2,
                // color: "#638e8a",
                // textShadow: "white 0.5px 0.5px",
                // backgroundColor: "#f0f0f0",
                // background: "linear-gradient(to right, black, #638e8a)",
                WebkitBackgroundClip: "text",
                // WebkitTextFillColor: "transparent",
                display: "inline-block",
                fontSize: { xs: "1rem", sm: "1.5rem", md: "1.75rem" }, // Responsive font size
                // color: (theme) =>
                //   theme.palette.mode === "light"
                //     ? "primary.main"
                //     : "primary.light",
                color: "white",
                textShadow: " 2px 2px #666666",
              }}
            >
              Efficiency, Simplify, Innovation at Your Fingertips
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MainSection;
