import React from "react";
import { Box, Typography } from "@mui/material";

const AboutSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        // backgroundColor: "#f9f9f9",
        backgroundColor: (theme) =>
          theme.palette.mode === "light" ? "#f9f9f9" : "primary.light",
      }}
      id="aboutUs"
    >
      <Box sx={{ maxWidth: 600, textAlign: "center" }}>
        <Typography variant="h4" component="h1" sx={{ mb: 2, fontWeight: 500 }}>
          About CAUTO
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ mb: 1.5, lineHeight: 1.6, fontSize: 18 }}
        >
          At CAUTO, we are dedicated to helping organizations streamline their
          processes and maximize efficiency through our software solutions.
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ mb: 1.5, lineHeight: 1.6, fontSize: 18 }}
        >
          Our team of experts is committed to providing top-notch customer
          service and innovative technology to meet the unique needs of each
          client.
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ lineHeight: 1.6, fontSize: 18 }}
        >
          With a focus on automation and workflow management, we strive to
          empower businesses to reach their full potential and achieve success.
          Join us in revolutionizing the way you manage your products and
          services.
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutSection;
